.dark {
  .part-contact {
    .form-wrapper {
      background-color: #27272b;
    }
  }
}

.part-contact {
  .container {
    display: flex;
    padding-left: 0;
  }

  .form-wrapper {
    position: relative;
    display: flex;
    left: -25px;
    flex: 0 0 65%;
    max-width: 65%;
    padding: 36px 110px 29px 50px;
    // width: 1196px;
    // height: 552px;
    box-shadow: 0 -4px 7px rgba(0, 0, 0, 0.15);
    background-color: #fbfbfb;
  }

  .title,
  .text {
    font-size: 20px;
    line-height: 36px;
  }

  .title {
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 22px;
  }

  .subtitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    padding: 8px 0 15px;
  }

  .info {
    font-size: 16px;
    line-height: 30px;
  }

  .info-col {
    flex: 0 0 28.5%;
    max-width: 28.5%;
  }  

  .contact-wrapper {
    padding-top: 36px;
    padding-left: 38px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .contact-list {
      margin-bottom: 48px;

      li {
        position: relative;
        margin-bottom: 20px;
        padding-left: 35px;
        font-size: 20px;
        font-weight: 400;
        line-height: 36px;
        color: inherit;
        fill: inherit;
      }

      img,
      svg {
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        max-width: 21px;
        height: auto;
        
      }

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          color: $red;

          ~svg {
            color: $red;
            fill: $red;
          }
        }

        &:not(:last-of-type) {
          margin-right: 54px;
        }
      }
    }

    .social-list {
      columns: 5 40px;

      li {
        margin-bottom: 20px;
      }

      a {
        display: block;
        color: inherit;
        max-width: 28px;

        &:hover {
          color: $red;
          fill: $red;
        }
      }
    }
  }  

  @media screen and (max-width: 1499px) {
    .form-wrapper {
      flex: 0 0 60%;
      max-width: 60%;
      padding-right: 70px;
    }

    .contact-wrapper {
      padding-left: 20px;

      .contact-list {
        a {
          &:not(:last-of-type) {
            margin-right: 20px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .container {
      padding-left: 0;
      padding-right: 0;
      flex-flow: wrap-reverse;
    }

    .form-wrapper,
    .contact-wrapper {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .form-wrapper {
      padding: 30px 15px;
      left: auto;
    }

    .contact-wrapper {
      padding: 40px 15px;
    }

    .title {
      width: 100%;
    }

    .col {
      width: 50%;
      float: left;
    }
  }

  @media screen and (max-width: 991px) {

    .title,
    .text {
      font-size: 18px;
      line-height: 1.75;
    }

    .contact-wrapper {
      .contact-list {
        li {
          font-size: 18px;
        }

        svg,
        img {
          top: 10px;
          transform: translateY(0);
        }

        a {
          display: block;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .form-wrapper {
      flex-wrap: wrap;
    }

    .info-col {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }

    .contact-form-col {      
      padding-bottom: 30px;
      border-bottom: 1px solid rgba(#000, .5);
    }

    .contact-wrapper {
      .contact-list {
        li {
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    .col {
      width: 100%;
    }
  }
}