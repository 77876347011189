.dark {
  .site-footer {
    background-color: #27272b;
    .footer-menu {
      a {
        color: #ffffff;
      }
    }
    .switch-theme {
      background-color: #2b2b2d;
      .slider-round {
        background-color: #2b2b2d;
        border-color: #2b2b2d;
      }
    }
  }
}
.site-footer {
  box-shadow: 0 -3px 17px rgba(0, 0, 0, 0.2);
  background-color: #fbfbfb;
  padding-top: 27px;
  padding-bottom: 7px;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .footer-left {
    display: flex;
    align-items: center;
  }

  .footer-menu {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    padding-left: 40px;

    li {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
    }

    a {
      display: block;
      color: #7e7b7b;
      font-size: 14px;
      line-height: 36px;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .switch-theme-wrapp {
    position: relative;
  }

  .switch-theme {
    display: block;
    position: relative;
    width: 90px;
    height: 44px;
    box-shadow: inset 0 -7px 12px rgba(0, 0, 0, 0.4);
    border-radius: 22px;
    border: 4px solid $red;
    background-color: #fbfbfb;
    cursor: pointer;

    input {
      display: block;
      width: 0;
      height: 0;
      visibility: hidden;
      z-index: -1;

      &:checked {
        +.slider-round {
          transform: translate(calc(100% + 4px), -50%);
        }
      }
    }

    .slider-round {
      display: block;
      position: absolute;
      top: 50%;
      left: -4px;
      transform: translate(0, -50%);
      width: 44px;
      height: 44px;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.44);
      border: 2px solid #f3f3f3;
      border-radius: 50%;
      background-color: #f5f5f5;
      transition: .4s;
    }
  }

  .switch-theme-label {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    color: #878585;
    font-size: 10px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
  }



  .branding {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 1.2px;
    padding-bottom: 20px;

    img {
      max-width: 47px;
    }

    .red {
      display: inline-block;
      padding-right: 5px;
      padding-left: 20px;
    }
  }

  @media screen and (max-width: 1199px) {
    .branding {
      font-size: 20px;

      img {
        max-width: 44px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .switch-theme-wrapp {
      .color {
        display: inline-block;
        padding-left: 3px;
        padding-right: 3px;
      }
    }

    .switch-theme {
      margin: auto;
      width: 70px;
      height: 35px;

      .slider-round {
        width: 35px;
        height: 35px;
      }

    }

    .footer-menu {
      padding-left: 20px;

      li {
        padding: 0 10px 20px 10px;
      }

      a {
        font-size: 14px;
        line-height: 2;
      }
    }

    .branding {
      font-size: 18px;

      img {
        max-width: 40px;
      }

      .red {
        padding-left: 10px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding-top: 10px;
    padding-bottom: 0;

    .footer-col {
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .branding {
      justify-content: center;
    }
  }

  @media screen and (max-width: 575px) {
    .footer-col {
      flex-wrap: wrap;
    }

    .switch-theme-wrapp {
      padding-bottom: 20px;
    }

    .footer-menu {
      flex-wrap: wrap;

      li {
        text-align: center;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
      }
    }


    .footer-logo {
      text-align: center;
    }

  }
}