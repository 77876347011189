.dark {
	header {
		&.sticky {
			background-color: #27272b;			
		}
		.btn-red {
			color: inherit;
		}
	}
}

header {
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	padding: 30px 0;
	margin: auto;
	transition: .4s;

	&.sticky {
		padding-top: 5px;
		padding-bottom: 5px;
		position: fixed;
		top: 0;
		background-color: #fff;
	}

	&.sticky-up {
		top: -200px;
	}

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.branding {
		display: flex;
		align-items: center;
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 1.2px;
		img,
		svg {
			display: block;
			max-width: 47px;
			height: auto;
		}
		svg {
			.hat, .mask {
				
			}
			.hat {
				opacity: 1;
				//animation: showDown 5s ease-in-out 0s infinite alternate-reverse;
			}
			.mask {
				//opacity: 0;
				//animation: showUp 5s ease-in-out 0s infinite alternate-reverse;
			}
		}

		span {
			display: inline-block;
			flex: 0 0 auto;
			
		}
		.logo {
			flex: 0 0 47px;
		}
		.red {
			padding-right: 7px;
			padding-left: 20px;
		}
	}
	.header-links {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	.langs {
		font-size: 16px;
		letter-spacing: 0.4px;
		.lang {
			&:not(:last-child) {
				margin-right: 10px;
			}
		}
		a{
			text-decoration: none;
			color: inherit;
			transition: color .4s;
			&:hover{
				color: red;
			}
		}
		span{
			font-weight: bold;
			color: red;
		}
	}
	.social {
		padding-right: 28px;

		a {
			display: inline-block;
			width: 19px;
			height: 19px;
			color: inherit;
			fill: inherit;
			vertical-align: middle;
			&:not(:last-child) {
				margin-right: 20px;
			}

			&:hover {
				fill: $red;
				color: $red;
				svg {
					fill: $red;
				}
			}
		}
		img, svg {
			display: block;
			max-width: 100%;
			height: auto;
			fill: inherit;
			transition: .4s;
		}
	}

	@keyframes showUp {
		0% {
			opacity: 0;
		}
		45% {
			opacity: 0;
		}
		50% {			
			opacity: 1;
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes showDown {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 1;
		}
		55% {
			opacity: 0;
		}
		100% {
			opacity: 0;
		}
	}

	@media screen and (max-width: 1199px) {
		.branding {
			font-size: 20px;
			.logo {
				flex: 0 0 44px;
			}
		}
	}

	@media screen and (max-width: 991px) {
		.branding {
			font-size: 18px;

			.logo {
				flex: 0 0 40px;
			}
			.red {
				padding-left: 10px;
			}
		}
	}

	@media screen and (max-width: 575px) {
		padding: 10px 0;
		.branding {
			.red,
			.black {
				display: none;
			}
		}
	}
}