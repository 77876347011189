.part-portfolio {
	.container {
		padding: 0;
		max-width: 1850px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.portfolio-intro {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-top: 50px;
		padding-bottom: 90px;
		border-bottom: 1px solid #bcbcbc;

		.title-block,
		.text-block {
			flex: 0 0 50%;
			max-width: 50%;
		}

		.title-block {
			padding-right: 15px;
		}

		.text-block {
			padding-left: 15px;
		}
	}

	.job {
		position: relative;
		height: 450px;
		display: flex;
		justify-content: center;
		padding: 0 60px;
		flex-direction: column;
		border-bottom: 1px solid #bcbcbc;
		transition: background-image .4s linear;

		&:first-child {
			border-top: 1px solid #bcbcbc;
		}

		&::before {
			content: '';
			position: absolute;
			z-index: -1;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			opacity: 0;
			background-repeat: no-repeat;
			background-size: cover;
			transition: opacity .7s linear;
		}

		&:hover {

			.type,
			.title,
			a {
				color: #fff;
			}

			&::before {
				opacity: 1;
			}
		}
	}

	.type {
		transition: color .4s;
	}

	.title {
		font-size: 48px;
		line-height: 36px;
		padding: 20px 0 40px;
		transition: color .4s;

		span {
			font-weight: 500;
		}
	}

	a {
		color: inherit;
		font-size: 18px;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 0.45px;
		text-decoration: none;
		transition: color .4s;

		span {
			vertical-align: top;
		}
	}

	.job1::before {
		background-image: url('../img/prt/jvs.jpg');
	}
	.job2::before {
		background-image: url('../img/prt/mukha-1.jpg');
	}
	.job3::before {
		background-image: url('../img/prt/Value.jpg');
	}
	.job4::before {
		background-image: url('../img/prt/aquarium.jpg');
	}

	@media screen and (max-width: 1199px) {
		.job {
			height: 400px;
		}

		.title {
			font-size: 40px;
		}
	}

	@media screen and (max-width: 991px) {
		.portfolio-intro {
			padding-top: 50px;
			padding-bottom: 50px;
			.title-block, .text-block {
				flex: 0 0 100%;
				max-width: 100%;
			}
			.text-block {
				padding-left: 0;
			}
		}
		.job {
			height: 360px;
			&::before {
				background-position: center center;
			}
		}
		.title {
			font-size: 36px;
		}
	}

	@media screen and (max-width: 767px) {
		.job {
			// margin-left: -15px;
			// margin-right: -15px;
			padding: 0 30px;
		}
		.title {
			font-size: 30px;
			line-height: 1.525
		}
		a {
			font-size: 16px;
		}
	}

	@media screen and (max-width: 575px) {
			.job {
				padding: 0 25px;
				color: #ffffff;
				&::before {
					opacity: 1;
					background-color: rgba($color: #000000, $alpha: .4);
					background-blend-mode: color;
				}
			}
			.type {
				color: inherit;
			}
			.title {
				font-size: 26px;
				color: inherit;
			}
			a {
				color: inherit;
				font-size: 14px;
			}
	}
}