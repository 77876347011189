.dark {
  .part-about-us {
    .box {
      .box-inner {        
        background-color: #27272b;
        background-image: none;
      }
    }
  }
}

.part-about-us {
  padding: 0 0 100px;

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 0;
  }

  .text-wrap {
    flex: 0 0 50%;
    max-width: 50%;
  }

  img {
    max-width: 50%;
    float: right;
    position: relative;
    margin-top: 18px;
    right: -25px;
  }

  .container-rev {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    padding-top: 106px;
    padding-right: 15px;
    padding-left: 0;

    .img {
      //height: 100%;
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 80px;
    }
    img {
      display: block;
      margin-top: 0;
      left: -25px;
      float: right;
      height: 100%;
      max-width: none;
    }
  }

  .boxes-block {    
    flex: 0 0 50%;
  }

  .boxes-wrapp {
    display: flex;
    flex-wrap: wrap;
    margin: -30px;
  }

  .box {
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;

    .box-inner {
      min-height: 330px;
      height: 100%;
      background: #e5dce8 url('/assets/img/bg.png');
      text-align: center;
      padding: 80px 30px 20px;
      box-shadow: -3px 3px 29px rgba(0, 0, 0, 0.3);
      cursor: default;
    }

    .title {
      min-height: 70px;
      font-size: 20px;
      font-weight: 500;
      line-height: 36px;
      text-transform: uppercase;
      margin-bottom: 25px;
    }

    .text {
      font-size: 20px;
      font-weight: 400;
      line-height: 36px;
    }
  }
  @media screen and (max-width: 1499px) {
    padding: 0 0 80px;
    .container-rev {
      .img {
        flex: 0 0 40%;
        max-width: 40%;
        padding-right: 25px;
      }
    }
    .boxes-block {
      flex: 0 0 60%;
    }
    .boxes-wrapp {
      margin: -30px -15px;
    }
    .box {
      padding-left: 15px;
      padding-right: 15px;
      .box-inner {
        padding: 60px 15px 20px;
      }
      .title {
        min-height: 70px;
      }
      .title, .text {
        font-size: 18px;
        line-height: 1.5;
      }
    }

    .container-rev {
      .img {
        padding-bottom: 60px;
      }
    }
  }
  @media screen and (max-width: 1199px) {
    .container {
      align-items: flex-start;
      flex-wrap: nowrap;
    }
    img {
      flex: 0 0 auto;
      max-width: none;
      width: auto;
      height: 100%;
      margin-top: 14px;
      float: left;
    }

    .box {
      .box-inner {
        padding: 40px 15px 15px;
        min-height: 300px;
      }
      .title, .text {
        font-size: 16px;
      }
    }

    .container-rev {
      .img {
        padding-right: 0;
      }
      img {
        height: initial;
        max-height: 660px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    img {
      margin-top: 12px;
    }
    .container-rev {
      flex-wrap: wrap;
      padding-right: 0;
      .img {
        position: relative;
        height: 360px;
        max-width: none;
        overflow: hidden;
        order: 1;
        flex: 0 0 100%;        
      }
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-height: none;
      }
    }
    .boxes-block {
      order: 0;
      flex: 0 0 100%;
      margin-bottom: 30px;
    }
    .boxes-wrapp {
      margin: 0;
    }
    .box {
      margin-top: 15px;
      margin-bottom: 15px;
      .title {
        min-height: 48px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding-top: 50px;
    padding-bottom: 50px;
    .container {
      padding-right: 15px;
      img {
        display: none;
      }
    }
    .container-rev {
      padding-top: 50px;
      padding-right: 0;
      .img {
        height: 320px;
      }
      img {
        display: block;
      }
    }
    .text-wrap {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .boxes-wrapp {
      flex-wrap: wrap;
    }
    .box {
      flex: 0 0 100%;
      max-width: 100%;
      .box-inner {
        min-height: auto;
        padding-bottom: 30px;
      }
      .title {
        min-height: 35px;
      }
    }
  }
}