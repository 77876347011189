/* ====================
# FONTS
==================== */
$f_family: 'Gotham';
$f_path: '../fonts/';

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: $f_family;
}

@mixin font-face($font-family, $file-name, $file-path, $weight: normal, $style: normal) {
	@font-face {
		font-family: $font-family;
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
		src: url('#{$file-path}.eot');
		src: local('#{$file-name}'),
		url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
		url('#{$file-path}.woff') format('woff'),
		url('#{$file-path}.ttf') format('truetype');
	}
}

// normal
@include font-face($f_family, 'Gotham Pro', $f_path + 'GothamPro' );
@include font-face($f_family, 'Gotham Pro Italic', $f_path + 'GothamPro-Italic', normal, italic );

// medium
@include font-face($f_family, 'Gotham Pro Medium', $f_path + 'GothamPro-Medium', 500 );
@include font-face($f_family, 'Gotham Pro Medium Italic', $f_path + 'GothamPro-MediumItalic', 500, italic );

// bold
@include font-face($f_family, 'Gotham Pro Bold', $f_path + 'GothamPro-Bold', bold );
@include font-face($f_family, 'Gotham Pro Bold Italic', $f_path + 'GothamPro-BoldItalic', bold, italic); 

// @include font-face($f_family, '', $f_path + '', );
