/* =====
FEATURES SECTION
===== */
.feats {
  padding: 120px 0 100px;
  .row {
    margin-right: -15px;
    margin-left: -15px;
    display: flex;
    flex-wrap: wrap;
  }
  .title-block {
    margin-bottom: 35px;
  }
  .feat-item {
    flex: 0 0 25%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .feat-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  // Feat Item
  .feat-head {
    flex: 0 0 140px;
    text-align: left;
    padding: 10px 10px;
    text-align: left;
    margin-bottom: 3px;
  }
  .icon {
    display: block;
    height: 125px;
    width: auto;
  }

  .feat-body {
    flex: 1 1 auto;
    max-width: 410px;
    padding: 15px 10px;    
  }
  .title {    
    min-height: 80px;
    margin-bottom: 7px;
  }
  .text-block {    
    line-height: 1.75;
  }

  /* MEDIA QUERY */
  @media screen and (max-width: 1499px) {
    .title {
      line-height: 1.5;
      min-height: 90px;
    }
  }
  @media screen and (max-width: 1199px) {
    .row {
      margin-right: -5px;
      margin-left: -5px;
    }
    .feat-item {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
  @media screen and (max-width: 991px) {
    .feat-item {
      flex: 0 0 50%;
      padding-bottom: 40px;
    }
    .title {
      min-height: 80px;
    }
  }
  @media screen and (max-width: 767px) {
    .title {
      min-height: 90px;
    }
  }
  @media screen and (max-width: 575px) {
    .feat-item {
      flex: 0 0 100%;
    }
    .feat-head {
      margin-right: auto;
      margin-left: auto;
    }
    .feat-body {
      max-width: none;
    }
    .title {
      min-height: auto;
    }
  }
}
