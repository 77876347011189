.dark {
  .modal-bg {
    background-color: rgba(0,0,0,0.4);
  }
  .modal-block {
    .modal-body {
      background-color: #27272b;
    }
    .modal-inner {
      box-shadow: -7px 7px 40px rgba(255, 255, 255, 0.2);
    }
    .modal-title {
      .white {
        color: #000;
      }
    }
  }
}

.modal-bg {
  background-color: rgba(255,255,255,0.4);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-block {
  display: none;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  &.open {
    display: block;    
  }
  
  .close {
    display: block;
    position: absolute;
    top: 11px;
    right: 11px;
    color: #acacac;
  }

  .modal-inner {
    display: flex;
    flex-wrap: wrap;
    max-width: 1220px;
    position: absolute;
    overflow-y: auto;
    box-shadow: -7px 7px 40px rgba(0, 0, 0, 0.2);
    top: 50%;
    left: -300%;
    width: 100%;
    margin: auto;
    transform: translate(-50%, -50%);
    transition: left .4s ease-in-out;
    &.open {
      left: 50%;
    }
  }

  .modal-header {
    display: flex;
    flex: 0 0 37.5%;
    max-width: 37.5%;
    padding-left: 15px;
    padding-right: 15px;
    background: url('/assets/img/modal-img.jpg') center center/cover no-repeat;
  }
  .modal-body {
    flex: 0 0 62.5%;
    max-width: 62.5%;
    padding: 110px 40px 110px 0;
    background-color: #f2f2f2;
  }

  .modal-title {
    margin: auto;
    color: inherit;
    font-size: 60px;
    font-weight: 700;
    line-height: 80px;
    .white {
      color: #fff;
    }
  }

  @media screen and (max-width: 991px) {
    .modal-inner {
      top: 0;
      transform: translateX(-50%);
    }
    .modal-header, .modal-body {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .modal-header {
      padding: 40px 15px;
    }
    .modal-title {
      font-size: 50px;
      line-height: 1.25;
    }
  }
  @media screen and (max-width: 575px) {
    .modal-body {
      padding: 25px 15px;
      .text {
        font-size: 18px;
      }
    }
    .modal-title {
      font-size: 38px;
    }
  }
}