.dark {
  .part-intro {
    background-color: #27272b;
    background-image: none;
  }
}

.part-intro {
  padding: 80px 0;
  background-color: #d2d2d2;
  background-image: url('../img/bg.png');

  &-grey {
    padding: 135px 0;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .title-block {
    padding-right: 100px;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .text-block {
    flex-basis: 0;
    flex-grow: 1;
  }

  @media screen and (max-width: 1499px) {
    padding-top: 80px;
    padding-bottom: 80px;

    .title-block,
    .text-block {
      flex: 0 0 50%;
    }

    .title-block {
      margin-right: 0;
      padding-right: 60px;
    }
  }

  @media screen and (max-width: 1199px) {}

  @media screen and (max-width: 991px) {

    .title-block,
    .text-block {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .title-block {
      padding-right: 0;
      margin-bottom: 20px;
    }
  }
}