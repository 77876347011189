h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0;
}

p {
	margin: 0;
}

.red {
	color: $red;
}

.title-block {
	font-size: 36px;
	font-weight: 700;
	line-height: 60px;
	text-transform: uppercase;
}

.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.8;
  text-transform: uppercase;
}

.text-block {
	font-size: 20px;
	line-height: 36px;
}
.post-title {
	font-size: 20px;
	font-weight: 700;
	a {
		color: inherit;
		text-decoration: none;
		transition: color .3s ease-in-out;
		&:hover {
			color: $red;
		}
	}
}

.list-block {
	list-style: none;
	padding-left: 0;
	font-size: 20px;
	line-height: 36px;

	li {
		position: relative;
		padding-left: 20px;
		margin-bottom: 15px;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 15px;
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background-color: $red;
			left: 0;
		}
	}
}

@media screen and (max-width: 1499px) {
	.title-block {
		font-size: 32px;
		line-height: 56px;
	}

	.text-block, .list-block {
		font-size: 18px;
		line-height: 32px;
	}
	.list-block {
		li {
			&::before {
				top: 12px;
			}
		}
	}
}

@media screen and (max-width: 1199px) {
	.title-block {
		font-size: 30px;
		line-height: 1.6;
	}
}

@media screen and (max-width: 991px) {
	.title-block {
		font-size: 28px;
		margin-bottom: 20px;
	}

	.text-block, .list-block {
		font-size: 16px;
		line-height: 1.7;
	}
	.list-block {
		li {
			&::before {
				top: 10px;
			}
		}
	}
}

@media screen and (max-width: 767px) {}

@media screen and (max-width: 575px) {
	.title-block {
		font-size: 24px;
	}

	.text-block, .list-block {
		line-height: 1.5;
	}
	.list-block {
		li {
			&::before {
				top: 9px;
			}
		}
	}
}