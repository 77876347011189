.part-capabilities {
  padding: 0 0 100px;
  .title-block {
    padding-bottom: 25px;
  }

  .text-block {
    padding-bottom: 30px;
  }

  ul, ol {
    column-count: 4;
    margin: 0;
    padding: 0;
    padding-left: 25px;
    font-size: 20px;
    font-weight: 500;
    line-height: 48px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  li {
    margin-bottom: 20px;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 1499px) {
    ul, ol {
      margin-bottom: 100px;
      column-count: 3;
      column-gap: 20px;      
    }
    li {
      padding-right: 20px;
    }
  }

  @media screen and (max-width: 1199px) {
    ul, ol {
      font-size: 16px;
    }
    li {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 992px) {
    ul, ol {
      column-count: 2;
      margin-bottom: 50px;
    }
    li {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 767px) {
    ul, ol {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 575px) {
    ul, ol {
      column-count: 1;
    }
  }
}