.part-blog {
  padding: 80px 0 100px;

  .title-block {
    margin-bottom: 60px;
  }

  .post-block {
    flex: 0 0 calc(50% + 30px);
    max-width: calc(50% + 30px);
  }

  article, .article {
    margin-bottom: 30px;
  }

  .date {
    font-size: 16px;
    font-weight: 500;
    line-height: 36px;
  }

  .excerpt {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;

    .post-link {
      display: block;
      float: right;
      text-decoration: none;
      font-weight: 500;
      color: #e00027;
      text-transform: uppercase;
    }
  }

  @media screen and (max-width: 1499px) {
    .post-block {
      flex: 0 0 60%;
      max-width: 60%;
    }
  }

  @media screen and (max-width: 1199px) {
    .post-block {
      flex: 0 0 75%;
      max-width: 75%;
    }
  }

  @media screen and (max-width: 991px) {
    .post-block {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .excerpt {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 767px) {
    .post-block {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .excerpt {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 575px) {
    .excerpt {
      font-size: 16px;
      .post-link {
        display: block;
        float: none;
      }
    }
  }
}