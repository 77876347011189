.dark {
  input {

    &[type=text],
    &[type=tel],
    &[type=email] {
      border-bottom: 1px solid rgba(#fff, .5);
      color: inherit;
    }
  }
}

form {
  label {
    position: relative;
    span {
      font-size: 16px;
      line-height: 24px;
    }
  }

  input {

    &[type=text],
    &[type=tel],
    &[type=email] {
      display: block;
      width: 100%;
      padding: 20px 0 3px;
      border: none;
      background-color: transparent;
      border-bottom: 1px solid rgba(#000, .5);
      font-size: 18px;

      &.has-err {
        border-bottom: 1px solid $red;
        box-shadow: 0 12px 15px -12px rgba($red, .5);
      }
    }

    &:focus {
      outline: none;
    }

  }

  .btn-form {
    min-width: auto;
    width: 200px;
    height: 51px;
    color: #f8f8f8;
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
  }
}

.form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
}

.form-row-bottom {
  padding-top: 14px;
  align-items: flex-start;

  .form-col {
    // flex: 0 0 auto;
    // max-width: 100%;
  }
}

.form-col {
  flex-basis: 0;
  flex-grow: 1;
  padding: 13px 15px;
}

.contact-form-col {
  padding-left: 40px;

  .text {
    font-size: 20px;
    line-height: 36px;
    font-weight: 500;
    margin-bottom: 6px;
  }
}

.response {
  max-width: 320px;
  .err, .ok {
    display: none;
    font-size: 12px;

    font-weight: 500;
    line-height: 1.2;
    &.has-err {
      display: block;
    }
  }
  .err {
    color: $red;
  }
  .ok {
    color: $blue;
  }
}

.resp {
  //display: none;
  width: 100%;
  padding: 5px 10px;
  transition: .4s;

  &.ok {
    border: 2px solid $blue;
  }

  &.err {
    border: 2px solid $red;
  }
}

@media screen and (max-width: 1499px) {}

@media screen and (max-width: 1199px) {}

@media screen and (max-width: 991px) {}

@media screen and (max-width: 767px) {
  form {
    input {

      &[type=text],
      &[type=tel],
      &[type=email] {
        font-size: 16px;
      }
    }
  }
  .contact-form-col {
    padding-left: 0;
  }
}

@media screen and (max-width: 575px) {
  .form-row {
    flex-wrap: wrap;
  }

  .form-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}