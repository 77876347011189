* {
	box-sizing: border-box;
}

body {
	background-image: url('../img/bg.png');
	background-color: #f5f5f5;
	color: #000;

	&.dark {
		background-color: #13131b;
		color: #fff;
		background-image: none;
	}

	&.open {
		overflow: hidden;
		-webkit-overflow-scrolling: touch;
		height: 100%;
		width: 100%;
		padding-right: 17px;
	}
}

.container {
	width: 100%;
	max-width: 1850px;
	margin: auto;
	padding: 0 15px;
	position: relative;
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
}
.col {
	padding-right: 15px;
	padding-left: 15px;
}

.page-wrap {
	width: 100%;
	max-width: 100%;
	overflow: hidden;
}

/* style.css */
.skip-link {
	position: absolute;
	top: -40px;
	left: 0;
	background: #000000;
	color: white;
	padding: 8px;
	z-index: 100;
}

.skip-link:focus {
	top: 0;
}

@media screen and (max-width: 1499px) {

}