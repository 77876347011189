.dark {
  .part-hero {

    .hero-form-col {
      .hero-form {
        background-color: #27272b;
      }
    }
    .bg-txt {
      -webkit-text-stroke: 6px #fff;
      opacity: .1;
    }


    .btn-wrp {
      svg {
        fill: #fff;
        stroke: #fff;

        .scl {
          fill: $red;
          stroke: $red;
        }

        .line {
          stroke: transparent;
        }
      }
    }
  }
}

.part-hero {
  position: relative;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 90px;
  padding-bottom: 150px;

  .row {
    align-items: center;
  }

  .hero-text-col {
    flex: 0 0 65%;

    .title {
      position: relative;
      z-index: 1;
      max-width: 1210px;
      padding-top: 28px;
      padding-bottom: 12px;
      font-size: 96px;
      font-weight: 700;
      line-height: 1.2;
      text-transform: uppercase;
    }

    .text {
      max-width: 1130px;
      font-size: 20px;
      font-weight: 500;
      line-height: 36px;
      padding-bottom: 40px;
    }
  }

  .hero-form-col {
    flex: 0 0 35%;
    display: flex;
    justify-content: flex-end;

    .hero-form {
      flex: 0 0 470px;
      max-width: 470px;
      background-color: #fff;
      padding: 65px 40px;
    }
  }

  .bg-txt {
    position: absolute;
    user-select: none;
    left: 200px;
    font-size: 500px;
    font-weight: bold;
    color: transparent;
    -webkit-text-stroke: 6px #000;
    opacity: .05;
    white-space: nowrap;
    display: block;
    width: 100%;
    z-index: -1;
  }

  .btn-wrp {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    margin: auto;

    svg {
      fill: inherit;
      stroke: #000;
    }

    .line {
      stroke: transparent;
    }

    .scl {
      animation: scroll 2s infinite;
    }

    .bar {
      fill: #b0afaf;
      stroke: transparent;
    }
  }



  @keyframes scroll {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }


  /* MEDIA QUERY */
  @media screen and (max-width: 1499px) {
    .hero-text-col {
      .title {
        font-size: 94px;
      }
    }

    .hero-form-col {
      .hero-form {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .bg-txt {
      font-size: 460px;
    }

    .btn-wrp {
      bottom: 0;
    }
  }

  @media screen and (max-width: 1199px) {
    .hero-text-col {
      .title {
        font-size: 80px;
      }

      .text {
        max-width: 800px;
      }
    }

    .hero-form-col {
      .hero-form {
        padding: 40px 25px;
      }
    }

    .bg-txt {
      font-size: 400px;
    }

  }

  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
    padding-bottom: 0;

    .hero-text-col {
      flex: 0 0 55%;

      .title {
        font-size: 64px;
      }

      .text {
        max-width: 620px;
      }
    }

    .hero-form-col {
      flex: 0 0 45%;
    }

    .bg-txt {
      font-size: 300px;
    }

    .btn-wrp {
      padding-top: 70px;
      position: static;
    }
  }

  @media screen and (max-width: 767px) {
    .hero-text-col {
      flex: 0 0 100%;

      .title {
        font-size: 48px;
      }

      .text {
        max-width: none;
      }
    }

    .hero-form-col {
      flex: 0 0 100%;
      max-width: none;
      padding-left: 0;
      padding-right: 0;
    }

    .bg-txt {
      left: 0;
      font-size: 200px;
    }
  }

  @media screen and (max-width: 575px) {
    padding-top: 85px;

    .bg-txt {
      font-size: 140px;
    }

    .title {
      font-size: 36px;
    }

    .text {
      font-size: 16px;
      line-height: 30px;
    }

    .btn {
      min-width: auto;
    }

    .next {
      max-width: 24px;
    }
  }
}