.part-tech {
	padding: 110px 0;
	font-size: 48px;
	font-weight: 500;
	line-height: 36px;
	text-transform: uppercase;
	letter-spacing: 1.2px;
	line-height: 1;

	.container {
		padding: 0;
		max-width: 1920px;
	}

	.f {
		margin-bottom: 60px;
	}
	marquee {
		word-spacing: 25px;
	}

	@media screen and (max-width: 1499px) {
		font-size: 44px;

		.f {
			margin-bottom: 55px;
		}
	}

	@media screen and (max-width: 1199px) {
		font-size: 40px;

		.f {
			margin-bottom: 50px;
		}
	}

	@media screen and (max-width: 991px) {
		font-size: 36px;

		.f {
			margin-bottom: 40px;
		}
	}

	@media screen and (max-width: 767px) {
		padding-top: 50px;
		padding-bottom: 50px;
		font-size: 32px;

		.f {
			margin-bottom: 35px;
		}
	}
	@media screen and (max-width: 575px) {
		font-size: 26px;

		.f {
			margin-bottom: 25px;
		}
	}
}