.main-navigation{
	transition: .4s;
	ul{
		padding: 0;
		margin: 0;
	}
	li{
		display: inline-block;
		vertical-align: middle;
		&:not(:last-child){
			margin-right: 15px;
		}
	}

	@media(max-width: 991px){
		position: fixed;
		right: -500px;
		top: 0;
		bottom: 0;
		width: 100%;
		max-width: 400px;
		padding: 20px;
		background-color: #fff;
		z-index: 999;
		&.active{
			right: 0;
		}
		li{
			display: block;
			&:not(:last-child){
				margin-right: 0;
			}
		}
	}
}


.menu-open,.menu-close{
	padding: 0;
	margin: 0;
	border: none;
	background-color: transparent;
	display: inline-block;
	width: 50px;
}

.menu-bg{
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(255,255,255,.6);
	z-index: -10;
	opacity: 0;
	transition: .4s;
	&.active{
		opacity: 1;
		z-index: 900;
	}
}

.menu-toggle{
	@media(min-width:992px){
		display: none !important;
	}
}