.part-content {
	padding: 0 0 50px;

	.container {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;
		padding-right: 0;

		>* {
			width: 47%;
		}

		img {
			position: relative;
			right: -25px;
		}
	}

	&.content-left {
		.container {
			padding-left: 0;
			padding-right: 15px;

			img {
				right: auto;
				left: -25px;
			}
		}
	}

	@media screen and (max-width: 1199px) {
		.title-block {
			margin-bottom: 20px;
		}
	}

	@media screen and (max-width: 991px) {
		.container {
			padding-left: 15px;
			padding-right: 15px;
			>* {
				width: auto;
			}
			img {
				display: block;
				width: 100%;
				right: auto;
			}
		}
		.text-wrap {
			flex: 0 0 100%;
			padding-bottom: 40px;
		}
	}
}