/* =====
PRICES SECTION
===== */
.dark {
  .block-prices {
    .summary-col {
      .ticker {
        color: $white;
        background-color: #27272b;
      }
    }

    .img-col {
      img {
        &.light-img {
          display: none;
        }
        &.dark-img {
          display: block;
        }
      }
    }
    @media screen and (max-width: 767px) {
      .summary-col {
        color: $black;

        .btn {
          background-color: $black;
          border-color: $black;
          color: $white;
          &::before {
            background-color: $black;
          }
        }
      }
    }
  }
}

.block-prices {
  // Price Box
  .box-price {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 90px;

    &:nth-child(even) {
      flex-direction: row-reverse;

      .img-col {
        img {
          margin: auto;
        }
      }

      .summary-col {
        text-align: right;
      }
    }

    &:last-child {
      padding-bottom: 62px;
    }
  }

  // Img Column
  .img-col {
    flex: 0 0 40%;
    text-align: center;
    padding-left: 40px;

    img {
      display: block;
      max-width: 100%;
      height: auto;
      filter: drop-shadow(5px 5px 10px #000);
      &.light-img {}
      &.dark-img {
        display: none;
      }
    }
  }

  // Summary Column
  .summary-col {
    flex: 0 0 40%;
    padding-top: 18px;
    padding-right: 15px;
    padding-left: 15px;

    .ticker {
      display: inline-block;
      margin-bottom: 18px;
      padding: 4px 16px;
      box-shadow: -1px 1px 10px rgba($black, 0.15);
      background-color: $grey;
      font-size: 16px;
      line-height: 1.2;
    }

    .title-block {
      margin-bottom: 12px;
    }

    .text-block {
      margin-bottom: 19px;
    }

    .prices {
      padding-top: 20px;
      padding-bottom: 20px;
      font-weight: 500;
      vertical-align: top;

      .sm {
        text-decoration: underline;
        text-decoration-color: $red;
        font-size: 60%;
      }

      .old {
        font-size: 24px;

        .price {
          position: relative;
          display: inline-block;

          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $red;
            top: calc(50% - 1px);
            transform: rotate(-8deg);
          }
        }
      }

      .new {
        padding-top: 11px;
        font-size: 48px;
      }
    }

    .actions {
      padding-top: 43px;
    }
  }

  /* MEDIA QUERY */
  @media screen and (max-width: 1499px) {

    .img-col,
    .summary-col {
      flex: 0 0 50%;
    }

    .img-col {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media screen and (max-width: 1199px) {
    .box-price {
      &:nth-child(even) {
        .img-col {
          img {
            margin: 0;
          }
        }
      }
    }

    .summary-col {
      .text-block {
        br {
          display: none;
        }
      }

      .prices {
        .old {
          font-size: 20px;
        }

        .new {
          font-size: 40px;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .summary-col {
      .prices {
        .old {
          font-size: 17px;
        }

        .new {
          font-size: 34px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .box-price {
      margin: 0 auto 50px;
      background-color: #f2f3fe;
      padding: 30px 20px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, .4);
      text-align: center;
      background-image: url(../img/bg.png);

      &:first-child {
        background-color: #dec6e5;
      }

      &:nth-child(2) {
        background-color: #d2fcfb;
      }

      &:last-child {
        background-color: #ebb1b1;
      }

      &:nth-child(even) {
        flex-direction: row-reverse;

        .img-col {
          img {
            margin: auto;
          }
        }

        .summary-col {
          text-align: inherit;
        }
      }
    }

    .img-col,
    .summary-col {
      flex: 0 0 100%;
    }

    .img-col {
      img {
        max-width: 240px;
        margin: auto;
      }
    }

    .summary-col {
      .text-block {
        max-width: 560px;
        margin: auto;
      }

      .actions {
        padding-top: 20px;
      }

      .btn {
        min-width: auto;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  @media screen and (max-width: 575px) {
    .box-price {
      padding: 30px 10px;

      &:last-child {
        margin-bottom: 90px;
      }
    }

    .summary-col {
      .btn {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
        max-width: 300px;
      }
    }
  }
}