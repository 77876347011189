.dark {
	.btn {
		border-color: #fff;
		color: #000;
		&::before {
			background: #fff;
		}
		&:hover {
			color: inherit;
		}

		&-red{
			border-color: $red;
			box-shadow: -4px 4px 51px rgba($red, 0.3);
			color: #fff;
			&::before {
				background-color: $red;
			}
			&:hover{
				color: $red;
			}
		}
		&-blue{
			color: #fff;
			border-color: $blue;			
			&::before {
				background-color: $blue;
			}
			&:hover{
				color: $blue;
			}
		}
	
		&-bb{
			color: $red;
			border-color:$red;
			&:hover{
				color: #fff;
				&::before {
					transform: scale(1);
				}
			}
			&::before {
				transform: scale(0);
				background:$red;
			}
		}
	}
}

button{
	background-color: transparent;
	padding: 0;
	border: none;
	padding: 0;
}
.btn{
	background-color: transparent;
	color: #fff;
	height: 70px;
	line-height: 70px;
	min-width: 300px;
	text-align: center;
	font-size: 18px;
	font-weight: 700;
	padding: 0 30px;
	border: none;
	text-decoration: none;
	box-shadow: -7px 7px 29px rgba(0, 0, 0, 0.5);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	transition-property: color;
	transition-duration: 0.3s;
	text-transform: uppercase;
	display: inline-block;
	vertical-align: middle;
	border: 2px solid #000;
	&::before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #000;
		transform: scale(1);
		transition-property: transform;
		transition-duration: 0.3s;
		transition-timing-function: ease-out;
	}

	&:hover {
		color: #000;
	}
	&:hover:before, &:focus:before, &:active:before {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	&-red{
		border-color: $red;
		box-shadow: -4px 4px 51px rgba($red, 0.3);
		&::before {
			background-color: $red;
		}
		&:hover{
			color: $red;
		}
	}
	&-blue{
		border-color: $blue;
		box-shadow: -4px 4px 51px rgba($blue, 0.3);
		&::before {
			background-color: $blue;
		}
		&:hover{
			color: $blue;
		}
	}

	&-bb{
		color: $red;
		border-color:$red;
		box-shadow: -4px 4px 51px rgba($red, 0.3);
		&:hover{
			color: #fff;
			&::before {
				transform: scale(1);
			}
		}
		&::before {
			transform: scale(0);
			background:$red;
		}
	}

	&-sm{
		min-width: 175px;
		height: 50px;
		line-height: 50px;
		font-size: 16px;
	}

	@media screen and (max-width: 767px) {
		padding: 0 7px;
		font-size: 16px;
	}

	@media screen  and (max-width: 575px) {
		min-width: auto;
		line-height: 50px;
		height: 50px;
		font-size: 14px;
		&-sm {
			min-width: 175px;
		}
	}

}